import SEO from "../../components/seo"
import BlogRoll from "../../components/BlogRoll"
import Container from "../../components/Container"
import Row from "../../components/Row"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"

const BlogIndexPage = () => {
  return (
    <>
      <SEO title="Blog" />
      <Helmet>
        {/* Font Awesome */}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
          integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
          crossorigin="anonymous"
        />
        {/* Materialize Icons */}
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        {/* Google Fonts */}
        <link
          href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Layout>
        <Container>
          <Row>
            <div className="col s12">
              <h1 className="blog-header">Blog</h1>
            </div>
          </Row>
        </Container>
        <BlogRoll />
      </Layout>
    </>
  )
}

export default BlogIndexPage
