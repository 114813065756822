import React, { Component } from "react"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import PreviewCompatibleImage from "./PreviewCompatibleImage"

import Container from "../components/Container"
import Row from "../components/Row"

class BlogRoll extends Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    let rowContents = []
    const contents = posts.map(({ node: post }, i) => {
      let acc = []
      rowContents.push(
        <div className="col s12 m4" key={post.id}>
          <article className="card">
            {post.frontmatter.featuredimage ? (
              <div className="card-image">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                  }}
                />
              </div>
            ) : null}
            <div className="card-content">
              <p>
                <Link
                  className="landing-page-header blog-title"
                  to={post.fields.slug}
                >
                  {post.frontmatter.title}
                </Link>
                <br />
                <div>
                  <span
                    className="blog-post-date"
                    style={{ display: "inline-block" }}
                  >
                    {post.frontmatter.date}
                    &nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;{post.timeToRead} min read
                  </span>
                </div>
              </p>

              <Link
                className="blog-post-date"
                to={`/authors/${kebabCase(post.frontmatter.author)}`}
              >
                {post.frontmatter.author}
              </Link>
              <p className="blog-description">
                {post.frontmatter.description.substring(0, 200)}...
              </p>
            </div>
            <Link className="button blog-roll-link" to={post.fields.slug}>
              Keep Reading &#62;
            </Link>
          </article>
        </div>
      )
      if ((i + 1) % 3 === 0) {
        acc.push(<Row>{rowContents}</Row>)
        rowContents = []
      }
      return acc
    }, [])
    contents.push(<Row>{rowContents}</Row>)

    return (
      <>
        <Helmet>
          {/* Font Awesome */}
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
            integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
            crossorigin="anonymous"
          />
          {/* Materialize Icons */}
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          {/* Google Fonts */}
          <link
            href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Container>{contents}</Container>
      </>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              timeToRead
              frontmatter {
                author
                description
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 450, maxHeight: 300, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
